<template>
  <div class="home">
    <CardList msg="Welcome to Your Vue.js App" />
  </div>
</template>

<script>
// @ is an alias to /src
import CardList from "@/components/CardList.vue";

export default {
  name: "Home",
  components: {
    CardList,
  },
};
</script>
