<template>
  <div>
    <h3>Event detail</h3>
  </div>
  <article v-if="event">
    <hr />
    <span>#{{ event.id }} </span>
    <span style="color: green; font-size: 12px; display: block">{{ event.date }}</span>
    <h4>{{ event.title }}</h4>
    <p>{{ event.location }}</p>
    <p>{{ event.description }}</p>
    <hr />
  </article>
</template>

<script>
import eventService from "../services/eventService";
export default {
  props: ["id"],
  data() {
    return {
      event: null,
    };
  },
  created() {
    eventService
      .getEvent(this.id)
      .then((res) => (this.event = res.data))
      .catch((error) => console.log(error));
  },
  name: "EventDetail",
};
</script>
