<template>
  <main>
    {{ msg }}
    <hr />

    <div class="list">
      <Card v-for="event in events" :data="event" :key="event.id" />
    </div>
  </main>
</template>

<script>
import Card from "./Card.vue";
import eventService from "../services/eventService";

export default {
  data() {
    return {
      events: null,
    };
  },
  created() {
    eventService
      .getEvents()
      .then((res) => (this.events = res.data))
      .catch((error) => console.log(error));
  },
  name: "Cardlist",
  components: { Card },
  props: {
    msg: String,
  },
};
</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<style scoped>
.list {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  flex-wrap: wrap;
  width: 100%;
  max-width: 100vw;
  box-sizing: border-box;
  background-color: rgba(188, 211, 255, 0.288);
  padding: 20px;
}
</style>
