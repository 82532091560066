import axios from "axios";

const api = axios.create({
    baseURL: 'https://my-json-server.typicode.com/code-pop/real-world_vue-3',
    withCredentials: false,
    headers: {
        'Accept': 'aplication/json',
        'Content-Type': 'aplication/json'
    }
});

export default {
    getEvents(){
        return api.get('/events')
    },
    getEvent(id){
        return api.get(`/events/${id}`)
    }
}