<template>
  <router-link :to="{ name: 'EventDetail', params: { id: data.id } }">
    <article>
      <span>#{{ data.id }}</span>
      <h4>{{ data.title }}</h4>
      <p>{{ data.location }}</p>
    </article>
  </router-link>
</template>

<script>
export default {
  props: {
    data: {
      type: Object,
      required: true,
    },
  },
  name: "Card",
};
</script>

<style scoped>
article {
  width: 200px;
  padding: 10px;
  margin: 5px;
  border: 3px solid green;
  border-radius: 15px;
  position: relative;
}

article:hover {
  background-color: darkgoldenrod;
  transform: scale(1.03);
  color: aliceblue;
  transition: 0.3s;
  cursor: pointer;
}

h4 {
  margin: 0;
}
p {
  margin: 0;
}

span {
  position: absolute;
  font-size: 10px;
  top: 5px;
  right: 10px;
}

a {
  text-decoration: none;
  color: black;
}
</style>
